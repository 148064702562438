import React, { useState, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import 'twin.macro'

import Layout from '../components/Layout'
import { H1 } from '../components/Headings'
import PestSelectModal from '../components/pests/PestSelectModal'
import PestCard from '../components/pests/PestCard'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../helpers'

const PestTypeLoader = () => {
  const loaders = Array(8).fill('')
  return loaders.map(e => <div tw="bg-gray-300 animate-pulse h-64"></div>)
}

const LearnPage = ({ data, location }) => {
  const [pestTypes, setPestTypes] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [selectedPestType, setSelectedPestType] = useState([])

  useEffect(() => {
    setPestTypes(
      data.pestTypes.nodes.map(x => {
        return {
          id: x.id,
          title: x.name,
          slug: x.slug,
          imagePath: x.featuredImage,
          description: x.description,
          pests: x.pests.nodes.map(pest => {
            return {
              fixedImagePath:
                pest.featuredImage.node.localFile.childImageSharp.fixed,
              fluidImagePath:
                pest.featuredImage.node.localFile.childImageSharp.fluid,
              title: pest.title,
              slug: pest.slug,
            }
          }),
        }
      })
    )
  }, [])

  const onPestTypeClick = pestTypeSlug => {
    const pestsForType = getPestsForTypeSlug(pestTypeSlug)

    if (pestsForType.length === 1) {
      navigate(`/learn/${pestsForType[0].slug}`)
    } else {
      const pestType = pestTypes.find(x => x.slug === pestTypeSlug)
      setSelectedPestType(pestType)
      setShowModal(true)
    }
  }

  const getPestsForTypeSlug = pestTypeSlug =>
    pestTypes.find(x => x.slug === pestTypeSlug).pests

  return (
    <>
      <div tw="bg-gradient-to-b from-blue-100 via-white to-white">
        <Layout location={location}>
          <Seo
            title={`Learn About Pests - ${
              JSON.parse(data.wp.seo.contentTypes.post.schema.raw)['@graph'][0]
                ?.name
            }`}
            postSchema={
              formatYoastSeo(data.wp.seo.contentTypes.post.schema).raw
            }
          />
          <PestSelectModal
            show={showModal}
            onClose={() => setShowModal(false)}
            pestType={selectedPestType}
            section={'learn'}
          />
          <H1 tw="text-center mt-8 mb-2 text-4xl md:(my-12)">
            Pests Around Your Home
          </H1>
          <div tw="container mx-auto px-4 md:px-12">
            {/* <div tw="flex flex-wrap justify-center mx-auto"> */}
            <div tw="flex flex-wrap justify-center">
              {!pestTypes.length ? (
                <PestTypeLoader />
              ) : (
                pestTypes.map(pestType => {
                  const pestsForType = getPestsForTypeSlug(pestType.slug)
                  return (
                    <PestCard
                      key={pestType.id}
                      title={pestType.title}
                      slug={pestType.slug}
                      imagePath={pestType.imagePath}
                      pests={pestsForType}
                      onClick={onPestTypeClick}
                    />
                  )
                })
              )}
            </div>
          </div>
        </Layout>
      </div>
    </>
  )
}

export const query = graphql`
  {
    wp {
      ...WpSeoSchema
    }
    pestTypes: allWpPestType(sort: { fields: name }) {
      nodes {
        pests {
          nodes {
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fixed(height: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                    fluid(maxWidth: 300) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            title
            slug
          }
        }
        id
        name
        slug
        featuredImage
        description
      }
    }
  }
`

export default LearnPage
